import React, { useState } from "react";
import "./AddDoc.css";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db, storage } from "../../utils/Firebase-Config";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

function AddDoc({ title, header, text, type, pointsReq, imageFile }) {
  
  const createDoc = async (e) => {
    e.preventDefault();

    if (!title) {
      window.alert("Missing fields");
      return;
    }

    if (imageFile) {
      const file = imageFile;

      const fileRef = ref(storage, `/module_icons/${file.name}`);

      uploadBytes(fileRef, file).then(() => {
        getDownloadURL(fileRef)
          .then(async (url) => {
            try {
              await addDoc(collection(db, "courses"), {
                url: url,
                title: title,
                header: header,
                text: text,
                timestamp: serverTimestamp(),
                isPublished: true,
                type: type,
              });
            } catch (error) {
              console.log(error);
            }
          })
          .catch((error) => {
            console.log(error.message, "Error getting the file url.");
          });
      });
    } else {
      try {
        await addDoc(collection(db, "courses"), {
          url: "",
          title: title,
          header: header,
          text: text,
          timestamp: serverTimestamp(),
          isPublished: true,
          type: type,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <button className="add_doc_btn" onClick={(e) => createDoc(e)}>
      Create
    </button>
  );
}

export default AddDoc;
