import React, { useState, useEffect, useRef } from "react";
import "./Splash.css";
import SignUp from "../../components/SignUp/SignUp";
import Login from "../../components/Login/Login";
import Logo from "../../components/Logo/Logo";
import SchoolIcon from '@mui/icons-material/School';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HeroBanner from "../../components/Hero/HeroBanner";
// import Cursor from "../../components/Cursor/Cursor";

function Splash({ navbar }) {



  const [toggle, setToggle] = useState(false);

  return (
    <>
    
      {toggle ? <Login toggle={toggle}/> : <Login toggle={toggle}/>}

      <div className="splash">

        <HeroBanner />

        <div className="section">

          <div className="mastheader">

            <Logo />

            <div className="button_wrapper">

              <div className="button col" onClick={() => {}}>
                <SchoolIcon />
                <h1>Scholar Program</h1>
              </div>

              <div className="button col" onClick={() => {}}>
                <AccountBalanceIcon />
                <h1>Signature Program</h1>
              </div>

            </div>

          </div>

        </div>

        {/* <div ref={sectionTwoRef} className="section two">
          <div className="hero"> 

            <button className="section_btn" onClick={() => {sectionOneRef.current?.scrollIntoView({behavior:"smooth"})}}><ArrowCircleUpIcon /></button>                  
            <SchoolIcon />
            <h1>Scholarship Program</h1>

          </div>

          <ul>
            <li>Investment Strategies</li>
            <li>Stocks & Options</li>
            <li>In-Depth Analysis</li>
            <li>Portfolio</li>
            <li>1 on 1 Coaching & Group Meet-Ups</li>
            <li>Additional Resourcees, Etc.</li>
          </ul>

        </div> */}

        {/* <div ref={sectionThreeRef} className="section three">
          <div className="hero">

            <button className="section_btn" onClick={() => {sectionOneRef.current?.scrollIntoView({behavior:"smooth"})}}><ArrowCircleUpIcon /></button>            
            <HistoryEduIcon />
            <h1>Signature Program</h1>

          </div>

          <ul>
            <li>Options</li>
            <li>Greeks</li>
            <li>Technical Analysis & More</li>
            <li>1 on 1 Coaching</li>
            <li>Group Meet-Ups</li>
            <li>Additional Resources</li>
          </ul>

        </div> */}
        
      </div>

    </>
  );
}

export default Splash;
