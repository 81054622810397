import React, { useContext, useEffect, useRef, useState } from "react";
import { GeneralUtilsContext } from "../../utils/GeneralUtilsContext";
import Modules from "../Modules/Modules";
import "./Carousel.css";
import { UserProfileContext } from "../../utils/UserProfileContext";
import { collection, doc, updateDoc } from "firebase/firestore";
import { db, storage } from "../../utils/Firebase-Config";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { display } from "@mui/system";

function Carousel({ carouselCards, level }) {
  const buttonRef = useRef();

  const { status } = useContext(UserProfileContext);

  const { setStage } = useContext(GeneralUtilsContext);

  const [imageFile, setImageFile] = useState();
  const [toggleIconEdit, setToggleIconEdit] = useState(false);

  const handleScroll = (i) => {
    if (!buttonRef) {
      return;
    }

    buttonRef.current.parentNode.childNodes[i].scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "center",
    });
  };

  const update = async (id) => {

    return

    if (imageFile && window.confirm("Add new icon?")) {
      const file = imageFile;

      const fileRef = ref(storage, `/module_icons/${file.name}`);

      uploadBytes(fileRef, file).then(() => {
        getDownloadURL(fileRef)
          .then(async (url) => {
            try {
              const docRef = doc(db, "courses", id);

              await updateDoc(docRef, {
                  //KEYS & VALUES THAT NEED UPDATING
                  url: url,
                },
                { merge: true }
              );
            } catch (error) {
              console.log();
            }
          })
          .catch((error) => {
            console.log(error.message, "Error getting the file url.");
          });
      });

      setToggleIconEdit(false)
    }

    return;
  };

//   useEffect(() => {
//     window.addEventListener("dblclick", () => setToggleIconEdit(true))
//   }, [])

  return (
    <>
      <div className="carousel_wrapper">
        <div className="carousel">
          {carouselCards &&
            carouselCards.map((module, index) => {
              if (module.data.level <= level || status === "admin") {
                return (
                  <div
                    className="carouselcard"
                    ref={buttonRef}
                    key={index}
                    onClick={(e) => {
                      setStage(<Modules id={module.id} data={module.data} />);
                    }}
                    onDoubleClick={() => setToggleIconEdit(true)}
                    onContextMenu={() => setToggleIconEdit(false)}
                  >

                    <div className="icon_update" style={{top: toggleIconEdit ? "0" : "-100vh", display: status === "admin" ? "grid" : "none"}}>
                      <input
                        name="field0"
                        type="file"
                        accept="image/png"
                        onChange={(e) => setImageFile(e.target.files[0])}
                      />
                      <button
                        className="add_doc_btn"
                        onClick={() => update(module.id)}
                      >
                        Add Icon
                      </button>
                    </div>
                    {/* <img className="module_icon" src={module.data.url} alt="" /> */}
                    <h1>{module.data.title}</h1>
                  </div>
                );
              } else {
                return (
                  <div
                    disabled
                    style={{ opacity: "50%", filter: "blur(.75px)" }}
                    className="carouselcard"
                    key={index}
                    ref={buttonRef}
                    onClick={() => {
                      window.alert(
                        "You do not currently have the requied points necessary to view this lesson."
                      );
                    }}
                  >
                    {/* <img className="module_icon" src={module.data.url} alt="" /> */}
                    <h1>{module.data.title}</h1>
                  </div>
                );
              }
            })}
        </div>

        {/* SCROLLBAR */}
        <div className="scroll_bar">
          {carouselCards &&
            carouselCards.map((card, index) => (
              <div
                key={index}
                className="carouselcard_blip"
                onMouseOver={() => {
                  handleScroll(index);
                }}
              ></div>
            ))}
        </div>
      </div>
    </>
  );
}

export default Carousel;
