import React, { createContext, useEffect, useState } from "react";
import platform from "platform";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db, auth } from "./Firebase-Config";

export const GeneralUtilsContext = createContext();

console.log(platform.os.family);

const GeneralUtilsContextProvider = (props) => {
  const [pending, setPending] = useState(false);

  //STOCK DATA
  const [symbol, setSymbol] = useState("AAPL");

  //PORTFOLIO UTILS
  const [currentProject, setCurrentProject] = useState("");

  //BLOG UTILS

  //CALENDAR UTILITIES

  const [currentDay, setCurrentDay] = useState("");
  const [currentMonth, setCurrentMonth] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [currentYear, setCurrentYear] = useState("");
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const day = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  //CLASSROOM DATA
  const [length, setLength] = useState("");
  const [currentVideo, setCurrentVideo] = useState("");
  const [currentTitle, setCurrentTitle] = useState(null);
  const [currentBrief, setCurrentBrief] = useState(null);
  const [edit, setEdit] = useState(false);
  const [toggleBlogForm, setToggleBlogForm] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [toggleAttachFilesForm, setToggleAttachFilesForm] = useState(false);
  const [currentClassroom, setCurrentClassroom] = useState("");
  const [currentClassId, setCurrentClassId] = useState("");
  const [commentCount, setCommentCount] = useState(0);
  const [roster, setRoster] = useState("");

  const [modulesMenu, setModulesMenu] = useState(false)

  const [stage, setStage] = useState(null);

  const getModulesMenu = ((e) => {

    e.preventDefault()

    if (modulesMenu) {
      setModulesMenu(false)
    } else {
      setModulesMenu(true)
    }
  })

  //GET MEMBER DATA BY ID
  const member = async (uuid) => {
    try {
      const memberRef = doc(db, "users", uuid);
      const docSnap = await getDoc(memberRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        return docSnap;
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  //PROCESS MESSAGES
  const sendMessage = async (e, to, reciever, header, message) => {
    e.preventDefault();

    if (!to || !reciever || !message) {
      return;
    }

    const messageRef = collection(db, "users", to, "messages");

    const { uid, displayName } = auth.currentUser;

    await addDoc(messageRef, {
      to: to,
      reciever: reciever,
      from: uid,
      sender: displayName,
      header: header,
      message: message,
      timestamp: serverTimestamp(),
    }).then(() => {
      alert("Message sent.");
    });
  };

  useEffect(() => {
    const currentTime = new Date();
    setCurrentDay(currentTime.getDay());
    setCurrentMonth(currentTime.getMonth());
    setCurrentDate(currentTime.getDate());
    setCurrentYear(currentTime.getFullYear());
  }, []);

  if (pending) {
    return <>Loading...</>;
  }

  return (
    <GeneralUtilsContext.Provider
      value={{
        length,
        setLength,
        currentVideo,
        setCurrentVideo,
        currentClassroom,
        setCurrentClassroom,
        currentClassId,
        setCurrentClassId,
        currentTitle,
        setCurrentTitle,
        currentBrief,
        setCurrentBrief,
        edit,
        setEdit,
        toggleBlogForm,
        setToggleBlogForm,
        toggleModal,
        setToggleModal,
        toggleAttachFilesForm,
        setToggleAttachFilesForm,
        commentCount,
        setCommentCount,
        roster,
        setRoster,
        currentYear,
        setCurrentYear,
        currentDate,
        setCurrentDate,
        currentMonth,
        setCurrentMonth,
        currentDay,
        setCurrentDay,
        month,
        day,
        currentProject,
        setCurrentProject,
        member,
        sendMessage,
        symbol,
        setSymbol,
        pending,
        setPending,
        getModulesMenu,
        modulesMenu,
        stage,
        setStage,
      }}
    >
      {props.children}
    </GeneralUtilsContext.Provider>
  );
};

export default GeneralUtilsContextProvider;
