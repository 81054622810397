import React, { useContext, useEffect, useState } from "react";
import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { db } from "../../utils/Firebase-Config";
import { GeneralUtilsContext } from "../../utils/GeneralUtilsContext"
import Modules from "./Modules";
import "./Modules.css"
import Carousel from "../Carousel/Carousel";


function ModulesSystem({level}) {

    const {getModulesMenu, modulesMenu, stage} = useContext(GeneralUtilsContext)

    const [value, setValue] = useState(null);
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [status, setStatus] = useState(null);
    const [created, setCreated] = useState(null);
    const [log, setLog] = useState(null);
    // const [level, setLevel] = useState(1);
  
    const [toggle, setToggle] = useState(false);
    const [live, setLive] = useState(false);
    const [room, setRoom] = useState(null);
  
    const [carouselCards, setCarouselCards] = useState(null);
    // const [stage, setStage] = useState(null);

  //Load & Update Module List on Points Change
    useEffect(() => {
    const getQuerySnapshot = async () => {
      try {

        const q = query(
          collection(db, "courses"),
          orderBy("level"),
          // where("type", "==", "subscription"),
          // where("pointsRequired", "<=", points)
        );

        onSnapshot(q, (querySnapshot) => {
          let temp = [];

          querySnapshot.forEach((doc) => {
            // console.log({ id: doc.id, data: doc.data() });
            temp.push({ id: doc.id, data: doc.data() });
          });

          setCarouselCards(temp);
        });
      } catch (error) {
        console.log(error);
      }
    };

    getQuerySnapshot();
  }, [level]);


  return (
    <div className="modules_container">
        <Carousel carouselCards={carouselCards} level={level}/>
        {stage}
    </div>
  );
}

export default ModulesSystem;
