import React, { useContext, useEffect } from "react";
import { db, auth } from "../../utils/Firebase-Config";
import {
  query,
  collection,
  onSnapshot,
  orderBy,
} from "firebase/firestore";
import { GeneralUtilsContext } from "../../utils/GeneralUtilsContext";
import Rostercard from "./Rostercard";
import "./Roster.css";

function Roster() {
  const { roster, setRoster } = useContext(GeneralUtilsContext);

  useEffect(() => {
    if (
      !auth.currentUser
    ) {
      return;
    }


    try {

      const q = query(
        collection(db, "users"),orderBy("created") //,where("classroom", "==", currentClassroom)
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let students = [];
        querySnapshot.forEach((doc) => {
          students.push({ ...doc.data(), id: doc.id });
        });

        setRoster(students)

      });

      return () => unsubscribe();
    } catch (error) {
      console.log(error);
    }
  }, [setRoster]);

  return (

      <div className="roster">
        {roster &&
          roster.map((student, index) => (
            <Rostercard
              key={student.id}
              id={student.id}
              photo={student.photo}
              name={student.name}
              email={student.email}
              created={student.created}
              log={student.log}
              status={student.status}
              points={student.points}
              data={student}
              level={student.level}
            />
          ))}
      </div>

  );
}

export default Roster;
