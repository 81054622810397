import React, { useContext, useEffect, useState, useRef } from "react";
import "./PaymentPlans.css";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import { auth, db } from "../../utils/Firebase-Config";
import { loadStripe } from "@stripe/stripe-js";
import { UserAuth } from "../../utils/AuthContext";

function PaymentPlans({ navbar }) {
  const { user } = UserAuth();

  const [products, setProducts] = useState("");
  const [subscription, setSubscription] = useState(null);
  const [pending, setPending] = useState(false);
// "pk_test_51NGneODbs0E03oPWBo7E3Z2GfbcDzJHVcq2QT7FyzPAWH5x0cm1kNbIYcVwG23w0C26sCgHgYjUzjzeVX38VJbEM00cH8uryIJ"
  const PUBLISHABLE_API_KEY = "pk_live_51NGneODbs0E03oPWFrqXyfFE7MY3G7yeIW4IHBmovpURPdb0YfE9D3AedGHQxqItK5qXJ3DHe0G5uodHMHnwmMSC007AweZSEM"

  //Get current subscription package
  useEffect(() => {
    const getSubscription = async () => {
      try {
        const querySnapshot = await getDocs(
          collection(db, "customers", user.uid, "subscriptions")
        );

        querySnapshot.forEach(async (subscription) => {
          setSubscription({
            status: subscription.data().status,
            role: subscription.data().role,
            current_period_start:
              subscription.data().current_period_start.seconds,
            current_period_end: subscription.data().current_period_end.seconds,
          });
        });
      } catch (error) {
        console.log(error.message);
      }
    };

    getSubscription();
  }, [user.uid]);

  //Get Subscription Packages
  useEffect(() => {
    const getPaymentPlans = async () => {
      try {
        const q = query(
          collection(db, "products"));

        const products = {};

        const querySnapshot = await getDocs(q)

        querySnapshot.forEach(async (productDoc) => {
          // doc.data() is never undefined for query doc snapshots

          // console.log(doc.id, " => ", doc.data());

          products[productDoc.id] = productDoc.data();

          const priceSnap = await getDocs(
            collection(db, "products", productDoc.id, "prices")
          );

          priceSnap.docs.forEach((price) => {
            products[productDoc.id].prices = {
              priceId: price.id,
              priceData: price.data(),
            };
          });

          setProducts(products);
        });

      } catch (error) {
        console.log(error);
      }
    };

    getPaymentPlans();
  }, []);


  const loadCheckout = async (priceId) => {
    setPending(true);

    const docRef = await addDoc(
      collection(db, "customers", user.uid, "checkout_sessions"),
      {
        // MODES: payment, subscription, & setup
        mode: "subscription",
        price: priceId,
        success_url: `${window.location.origin}/lessons`,
        cancel_url: `${window.location.origin}`,
        timestamp: serverTimestamp(),
      }
    );

    onSnapshot(
      doc(db, "customers", user.uid, "checkout_sessions", docRef.id),
      async (snap) => {
        const { error, sessionId } = snap.data();

        if (error) {
          alert(`An error occured: ${error.message}`);
        }

        if (sessionId) {
          //TODO: NEEDS PUBLISHABLE API KEY
          const stripe = await loadStripe(PUBLISHABLE_API_KEY);
          stripe.redirectToCheckout({ sessionId });
        }
      }
    );
  };

  return (
    <div className="plans_wrapper">

      { navbar }
        
      {/* <div className="subscription_statusbar">
        Subscription: {subscription ? subscription.role : "Pending..."}
        {subscription && <h1>Renewal date: {new Date(subscription?.current_period_end * 1000).toLocaleDateString()}</h1>}
      </div> */}

      {Object.entries(products).map(([productId, productData]) => {

        //Logic to check if user sub is active
        const isCurrentPackage = productData.name.includes(subscription?.role);          

        return (

          <div key={productId}>
          
          <div
            className="plans_card">

            <div className="plan_cover" style={{ backgroundImage: `url(${productData.images})` }}></div>


              <h1>{productData.name}</h1>
              <p>{productData.description}</p>


            <button
              className="purchase_btn"
              onClick={() => { 
                !isCurrentPackage && 
                loadCheckout(productData.prices.priceId)}}>
              {
              isCurrentPackage ? "Current Package" : `Subscribe`
              }
            </button>
          </div>
          </div>

        );

      })}

    </div>
  );
}

export default PaymentPlans;
