import React, { useContext, useEffect, useState } from "react";
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "../../utils/Firebase-Config";
import "./Lessons.css";
import Modules from "../../components/Modules/Modules";
import CreateCourseForm from "../../components/Forms/CreateCourseForm";
// import { GeneralUtilsContext } from "../../utils/GeneralUtilsContext";
import CancelIcon from '@mui/icons-material/Cancel';
import HeroBanner from "../../components/Hero/HeroBanner";
import ModulesSystem from "../../components/Modules/ModulesSystem";

function Lessons({ navbar }) {

  // const {getModulesMenu, modulesMenu} = useContext(GeneralUtilsContext)

  const [value, setValue] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [status, setStatus] = useState(null);
  const [created, setCreated] = useState(null);
  const [log, setLog] = useState(null);
  const [level, setLevel] = useState(1);

  const [toggle, setToggle] = useState(false);
  const [live, setLive] = useState(false);
  const [room, setRoom] = useState(null);

  const [modules, setModules] = useState(null);
  const [stage, setStage] = useState(null);


  //User
  useEffect(() => {
    const docRef = doc(db, "users", auth.currentUser.uid);

    onSnapshot(docRef, (doc) => {
      setName(doc.data().name);
      setEmail(doc.data().email);
      setStatus(doc.data().status);
      setCreated(doc.data().created);
      setLog(doc.data().log);
      setLevel(doc.data().level);
    });

  }, []);

  //Check for Live Event
  useEffect(() => {
    const docRef = doc(db, "settings", "activity");

    try {
      onSnapshot(docRef, (doc) => {
        setLive(doc.data().isLive);
        setRoom(doc.data().room);
      });
    } catch (error) {}
  }, []);

  const update = async (e) => {
    e.preventDefault();

    if (!value) {
      return;
    }

    const docRef = doc(db, "users", auth.currentUser.uid);

    await updateDoc(
      docRef,
      {
        //KEYS & VALUES THAT NEED UPDATING
        name: value,
      },
      { merge: true }
    );

    setToggle(false);
  };

  //Load & Update Module List on Points Change
  useEffect(() => {
    const getQuerySnapshot = async () => {
      try {

        const q = query(
          collection(db, "courses"),
          orderBy("pointsRequired"),
          where("type", "==", "subscription"),
          // where("pointsRequired", "<=", points)
        );

        onSnapshot(q, (querySnapshot) => {
          let temp = [];

          querySnapshot.forEach((doc) => {
            // console.log({ id: doc.id, data: doc.data() });
            temp.push({ id: doc.id, data: doc.data() });
          });

          setModules(temp);
        });
      } catch (error) {
        console.log(error);
      }
    };

    getQuerySnapshot();
  }, [level]);

  // const watch = () => {

  // }
  return ( 
    <>

    {navbar}
    
      <div className="lessons">

        {/* <HeroBanner /> */}

        {/* <div className="modules_container">

          <div className="modules_list">

            {modules &&
              modules.map((module, index) => {

                if (module.data.level <= level) {
                  return (
                    <button
                      className="mod_btn"
                      key={index}
                      onClick={(e) => {
                        setStage(<Modules id={module.id} data={module.data} />)
                        getModulesMenu(e)
                      }}
                    >
                      {module.data.title}
                    </button>                  
                  )
                } else {
                  return (
                    <div style={{opacity: "50%", filter: "blur(.75px)"}}
                      className="mod_btn"
                      key={index}
                      onClick={() => {
                        window.alert("You do not currently have the requied points necessary to view this lesson.")
                      }}
                    >
                      {module.data.title}
                    </div>                  
                  )                
                }

            })}
          </div>        

          {stage}
                   
        </div> */}

        <ModulesSystem level={level}/>

      </div>
      
    </>
  );
}

export default Lessons;
