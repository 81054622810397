import React, { useEffect, useContext, useState } from "react";
import { UserProfileContext } from "../../utils/UserProfileContext";
import Calendar from "../../components/Calendar/Calendar";
import Roster from "../../components/Roster/Roster";
import Scheduler from "../../components/Scheduler/Scheduler";
import "./Admin.css";
import { useNavigate } from "react-router-dom";
import { addDoc, collection, doc, getDoc } from "firebase/firestore";
import { auth, db, storage } from "../../utils/Firebase-Config";
import CreateCourseForm from "../../components/Forms/CreateCourseForm";
import Facetime from "../../components/Facetime/Facetime";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import Uploader from "../../components/Uploader/Uploader";
import Hero from "../../components/Hero/Hero";

function Admin({ navbar }) {
  const navigate = useNavigate();

  const { status, setStatus } = useContext(UserProfileContext);
  const [hero, setHero] = useState();
  const [imageUrl, setImageUrl] = useState();

    const [fileType, setFileType] = useState("image");
  const [path, setPath] = useState("");
  const [title, setTitle] = useState("");
  const [brief, setBrief] = useState("");
  const [section, setSection] = useState("lesson");
  const [pointsRequired, setPointsRequired] = useState(0);
  const [input, setInput] = useState("");
  const [icon, setIcon] = useState("");
  const [ft, setFt] = useState("image");
  const [pending, setPending] = useState(false)

  
  useEffect(() => {

    const userData = async () => {
      const userDocRef = doc(db, "users", auth.currentUser.uid);
  
      const docSnap = await getDoc(userDocRef);
  
      if (docSnap.exists()) {

        if (docSnap.data().status !== "admin") {
          navigate("/*");
        };

        setStatus(docSnap.data().status)

      } else {
        // doc.data() will be undefined in this case
        // setStatus("blocked");
      }
    };

    userData()

  }, [navigate]);

  return (
    <>
      {navbar}

      <div className="admin_layout">

        <div className="hero_component">
          <h1>Change Hero Banner</h1>
          <Hero directory={"heroes"}/>
        </div>

        <CreateCourseForm />

        <Roster />

      </div>
    </>
  );
}

export default Admin;
