import React, { useContext, useEffect, useState } from "react";
import "./Home.css";
import { GeneralUtilsContext } from "../../utils/GeneralUtilsContext";
import { UserProfileContext } from "../../utils/UserProfileContext";
import Blog from "../../components/Blogger/Blog";
import { collection, doc, getDoc, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../utils/Firebase-Config";
import HeroBanner from "../../components/Hero/HeroBanner";

function Home({ navbar }) {

  const {status} = useContext(UserProfileContext)
  const { toggleBlogForm, setToggleBlogForm, setEdit } =
    useContext(GeneralUtilsContext);

  const [hero, setHero] = useState()

  useEffect(() => {

    const userData = async () => {

      const q = query(collection(db, "heroes"), orderBy("timestamp"));

      onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
            console.log(doc.data().path );
            setHero(doc.data().path)
          });
      })
      
    };

    userData()

  }, [])

  return (
    
    <>   

      {navbar}

      <div className="home_layout">

        <HeroBanner />

        <Blog />

      </div>
      
    </>
    
  );
}

export default Home;