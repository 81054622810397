import { addDoc, collection, doc, getDoc, serverTimestamp, setDoc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../utils/Firebase-Config";
import "./Video.css";
// import ReactPlayer from "react-player";
//import { GeneralUtilsContext } from "../../utils/GeneralUtilsContext";


function Video({url, id, uid}) {

  //const {} = useContext(GeneralUtilsContext);
  const [isPlaying, setIsPlaying] = useState(false)
  const [time, setTime] = useState(0)
  const [completed, setCompleted] = useState(false)
  
  function handlePlay(e) {
    isPlaying === false ? setIsPlaying(true) : setIsPlaying(false)
    e.target.currentTime = time
  }

  const handleSeek = (e) => {

    const current = e.target.currentTime

      if (current > time) {
        e.target.currentTime = time
      }

  }


  useEffect(() => {
  
    const getProgress = async () => {

      const docRef = doc(db, "users", uid, "progress", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {

        console.log("Document data:", docSnap.data().completed);
        console.log("Document data:", docSnap.data().videoProgress);
        setTime(docSnap.data().videoProgress)
        setCompleted(docSnap.data().completed)
        
      } else {

        try {

          const companyRef = collection(db, "users", uid, "progress");

          await setDoc(doc(companyRef, id), {
            
            completed: completed,
            videoProgress: time,
            timestamp: serverTimestamp(),

          },
          { merge: true }).then(() => {
            console.log(`Progress document ${id} created.`);
          });

      } catch (error) {
          console.log(error);
      } 
        console.log(`Progress Document ${id} Created!`);
      }

    } 

    getProgress()

  }, [])


  const saveProgress = () => {
    
  }

  const handlePoints = async () => {
      const docRef = doc(db, "users", uid);
  
      await updateDoc(docRef, {
  
      //KEYS & VALUES THAT NEED UPDATING
      points: 100,
  
      },{ merge: true });
  }


  return (

    <>
      <div className="video_context_menu">
        {/* <button onClick={() => saveProgress()}>Save</button> */}
      </div>
      <video onPlay={(e) => handlePlay(e)} onTimeUpdate={(e) => setTime(Math.round(e.target.currentTime))} onSeeking={(e) => handleSeek(e)} onPause={() => handlePlay()} onEnded={(e) => {handlePoints()}}
      src={url}
      width="100%"
      height="auto"
      controls
      controlsList="nodownload"
      muted
      />
    </>
  );
}

export default Video;
