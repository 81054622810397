import React, { createContext, useEffect, useState } from "react";
import { auth, db } from "./Firebase-Config";
import {
  collection,
  doc,
  getDoc,
  limit,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";

export const UserProfileContext = createContext();

const UserProfileContextProvider = (props) => {
  const [pending, setPending] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [photo, setPhoto] = useState("");
  const [status, setStatus] = useState("");
  const [userPoints, setUserPoints] = useState("");
  const [created, setCreated] = useState("");
  const [log, setLog] = useState("");
  const [id, setId] = useState(null);
  const [subscription, setSubscription] = useState(null);

  useEffect(() => {
    if (!auth.currentUser) {
      return;
    }

    setName(auth.currentUser.displayName);
    setPhoto(auth.currentUser.photoURL);
    setEmail(auth.currentUser.email);
    setId(auth.currentUser.uid);
    setCreated(auth.currentUser.metadata.creationTime);
    setLog(auth.currentUser.metadata.lastSignInTime);
  }, [setName, setId]);

  //User Profile Data
  useEffect(() => {
    if (!auth.currentUser) {
      return;
    }
    userData();
    getSubscription();
  }, []);

  const userData = async () => {
    try {
      const userDocRef = doc(db, "users", auth.currentUser.uid);

      const docSnap = await getDoc(userDocRef);

      if (docSnap.exists()) {
        setStatus(docSnap.data().status);
        setUserPoints(docSnap.data().points);
      } else {
        // doc.data() will be undefined in this case
        setStatus("blocked");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubscription = async () => {
    const q = query(
      collection(db, "customers", auth.currentUser.uid, "subscriptions"),
      orderBy("created"),
      limit(1)
    );

    onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        // console.log({ id: doc.id, data: doc.data().created.seconds });
        setSubscription(doc.data());
      });
    });
  };

  if (pending) {
    return <>Loading...</>;
  }

  return (
    <UserProfileContext.Provider
      value={{
        name,
        setName,
        email,
        setEmail,
        photo,
        setPhoto,
        id,
        setId,
        created,
        setCreated,
        log,
        setLog,
        pending,
        setPending,
        status,
        setStatus,
        userPoints,
        setUserPoints,
        subscription,
        setSubscription,
      }}
    >
      {props.children}
    </UserProfileContext.Provider>
  );
};

export default UserProfileContextProvider;
