import React, { useState, useContext, useEffect } from 'react'
import "./Blog.css"
import ImageIcon from '@mui/icons-material/Image';
import { UserProfileContext } from '../../utils/UserProfileContext';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { addDoc, collection, doc, getDocs, onSnapshot, orderBy, query, serverTimestamp, setDoc } from 'firebase/firestore';
import { auth, db, storage } from '../../utils/Firebase-Config';
import Blogcard from "./Blogcard"
import CloseIcon from '@mui/icons-material/Close';
import LaunchIcon from '@mui/icons-material/Launch';

function Blog() {

    const {status} = useContext(UserProfileContext)

    const [title, setTitle] = useState()
    const [body, setBody] = useState()
    const [media, setMedia] = useState()
    const [path, setPath] = useState("");
    const [blogs, setBlogs] = useState()

    const [toggleBlogger, setToggleBlogger] = useState(false)

    const directory = "/blogs";

    // const handleSubmit = (e) => {
    //     e.preventDefault()
    // }

    const handleSubmit = (e) => {

        e.preventDefault();

      if (!title || !body) {return}      

          if (media) {
            
            const fileRef = ref(storage, `/blog_media/${media.name}`);

            uploadBytes(fileRef, media)
            .then(() => {
              getDownloadURL(fileRef)
                .then((url) => {

                    console.log(url);

                    const settingsRef = collection(db, directory);

                    setDoc(
                      doc(settingsRef),
                      {
                        media: url,
                        title: title,
                        body: body,
                        type: media.type,
                        timestamp: serverTimestamp(),
                      },
                      { merge: true }
                    );

                }).then(() => {
                  reset()
                })
                .catch((error) => {
                  console.log(error.message, "Error getting the file url.");
                });
            })
            .catch((error) => {
              console.log(error.message);
            });

            } else {

                const settingsRef = collection(db, directory);

                setDoc(
                    doc(settingsRef),
                    {
                      title: title,
                      body: body,
                      timestamp: serverTimestamp(),
                    },
                    { merge: true }
                ).then(() => {
                  reset()
                })
            }
    
      };

    const reset = () => {
        setTitle(null)
        setBody(null)
        setMedia(null)
    }

    useEffect(() => {
       
      const getQuerySnapshot = async () => {
        try {
  
          const q = query(
            collection(db, "blogs"),
            orderBy("timestamp", "desc"),
            // where("pointsRequired", "<=", points)
          );
  
          onSnapshot(q, (querySnapshot) => {
            let temp = [];
  
            querySnapshot.forEach((doc) => {
              // console.log({ id: doc.id, data: doc.data() });
              temp.push({ id: doc.id, data: doc.data() });
            });
  
            setBlogs(temp);
          });
        } catch (error) {
          console.log(error);
        }
      };
  
      getQuerySnapshot();

      console.log(blogs);

    }, [])


  return (

    <>

    {status === "admin" ? <button className='blog_btn' type='button' onClick={() => toggleBlogger ? setToggleBlogger(false) : setToggleBlogger(true)}>{ toggleBlogger ? <CloseIcon /> : <LaunchIcon />}</button> : null}

    <div className='blog_wrapper'>

        {status === "admin" ? <form className='blog_forms' onSubmit={(e) => {handleSubmit(e)}} style={{bottom: toggleBlogger ? "0" : "-100vh"}}>

            <div className="blog_options_menu">
                <div className='options_button'><input type="file" accept="image/png, image/jpeg, video/mp4" onChange={(e) => setMedia(e.target.files[0])} /><ImageIcon /></div>
            </div>

            <input required type="text" onChange={(e) => setTitle(e.target.value)} />

            <textarea required type="text" onChange={(e) => setBody(e.target.value)} />

            <div className="col">
                <button type='reset' onClick={() => reset()}>Cancel</button> 
                <button type='submit'>Submit</button>
            </div>

        </form> : null}        

        <div className="blogs_container">

            {blogs && blogs.map((blog, index) => (

                <Blogcard key={index} id={blog.id} title={blog.data.title} body={blog.data.body} type={blog.data.type} timestamp={blog.data.timestamp} url={blog.data.media}/>

            ))}

        </div>
        
    </div>
    
    </>

  )
}

export default Blog