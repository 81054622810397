import React, { useState, useContext, useEffect } from "react";
import { db, storage } from "../../utils/Firebase-Config";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { GeneralUtilsContext } from "../../utils/GeneralUtilsContext";
import VideocamIcon from "@mui/icons-material/Videocam";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import "./Hero.css";
import { UserProfileContext } from "../../utils/UserProfileContext";
import PostAddIcon from '@mui/icons-material/PostAdd';

function Hero({ directory, modulePointsRequired }) {
  //ADMIN CONTROLS MUST HAVE ADMIN STATUS
  const { status } = useContext(UserProfileContext);
  //______________________________________________________________

  const { currentClassId, currentTitle, setCurrentTitle, setCurrentVideo } =
    useContext(GeneralUtilsContext);

  const { userPoints } = useContext(UserProfileContext)

  const [fileType, setFileType] = useState("image");
  const [path, setPath] = useState("");
  const [title, setTitle] = useState("");
  const [brief, setBrief] = useState("");
  const [section, setSection] = useState("lesson");
  const [pointsRequired, setPointsRequired] = useState(0);
  const [input, setInput] = useState("");
  const [icon, setIcon] = useState("");
  const [ft, setFt] = useState("image");
  const [pending, setPending] = useState(false)

  const [toggleCreateModule, setToggleCreateModule] = useState(false)

  const handlefileUpload = (e) => {
    e.preventDefault();

    const file = e.target.files[0];

    const fileRef = ref(storage, `/${fileType}/${file.name}`);

    setPending(true)

    uploadBytes(fileRef, file)
      .then(() => {
        getDownloadURL(fileRef)
          .then((url) => {
            setPath(url);

            addDoc(
              collection(db, directory),
              {
                path: url,
                type: ft,
                timestamp: serverTimestamp(),
              },
              { merge: true }
            );

          })
          .then(() => {
            setPending(false)

          })
          .catch((error) => {
            console.log(error.message, "Error getting the file url.");
          });
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (

    <>
      {/* <div className="loading_component" style={{display: pending ? "grid" : "none"}}><h1>Loading...</h1></div> */}

      <input
        type="file"
        value={input}
        className="hero_btn"
        onChange={(e) => handlefileUpload(e)}
        accept="image/jpeg, image/png, video/mp4, video/mpeg, video/mov"
      />

    </>
  );
}

export default Hero;
