import React from "react";
import {
  LinkedinShareButton,
  TwitterShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  LinkedinIcon,
  TwitterIcon,
  FacebookIcon,
  WhatsappIcon,
} from "react-share";

import "./SocialMediaTools.css"

function SocialMediaTools({ url, site, title, desc, image, usersHandle }) {
  const source = "https://www.bulliesandbears.com/";

  return (
    <div className="socialmedia_toolbar">
      <LinkedinShareButton
        url={url}
        title={title}
        summary={desc}
        source={source}
      >
        <LinkedinIcon />
      </LinkedinShareButton>
      <TwitterShareButton
        url={url}
        title={site}
        image={image}
        via={source}
        hashtags={["bulliesandbears"]}
        related={[usersHandle, "@bulliesandbears"]}
      >
        <TwitterIcon />
      </TwitterShareButton>
      <FacebookShareButton url={url} quote={desc} hashtags={["bulliesandbears"]}>
        <FacebookIcon />
      </FacebookShareButton>
      <WhatsappShareButton
        url={url}
        title={site}
        separator= "|"
      >
        <WhatsappIcon />
      </WhatsappShareButton>
    </div>
  );
}

export default SocialMediaTools;
