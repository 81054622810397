import React, { useContext, useEffect, useState } from "react";
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import Avatar from "../../components/Avatar/Avatar";
import Subscription from "../../components/Subscription/Subscription";
import { auth, db } from "../../utils/Firebase-Config";
import "./Profile.css";
import Modules from "../../components/Modules/Modules";
import CreateCourseForm from "../../components/Forms/CreateCourseForm";
import { GeneralUtilsContext } from "../../utils/GeneralUtilsContext";
import CancelIcon from '@mui/icons-material/Cancel';

function Profile({ navbar, toggler }) {

  const {getModulesMenu, modulesMenu} = useContext(GeneralUtilsContext)

  const [value, setValue] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [status, setStatus] = useState(null);
  const [created, setCreated] = useState(null);
  const [log, setLog] = useState(null);
  const [points, setPoints] = useState(0);

  const [toggle, setToggle] = useState(false);
  const [live, setLive] = useState(false);
  const [room, setRoom] = useState(null);
  const [close, setClose] = useState(toggler)

  
  //User
  useEffect(() => {
    const docRef = doc(db, "users", auth.currentUser.uid);

    onSnapshot(docRef, (doc) => {
      setName(doc.data().name);
      setEmail(doc.data().email);
      setStatus(doc.data().status);
      setCreated(doc.data().created);
      setLog(doc.data().log);
      setPoints(doc.data().points);
    });

  }, []);

  //Check for Live Event
  // useEffect(() => {
  //   const docRef = doc(db, "settings", "activity");

  //   try {
  //     onSnapshot(docRef, (doc) => {
  //       setLive(doc.data().isLive);
  //       setRoom(doc.data().room);
  //     });
  //   } catch (error) {}
  // }, []);

  const update = async (e) => {
    e.preventDefault();

    if (!value) {
      return;
    }

    const docRef = doc(db, "users", auth.currentUser.uid);

    await updateDoc(
      docRef,
      {
        //KEYS & VALUES THAT NEED UPDATING
        name: value,
      },
      { merge: true }
    );

    setToggle(false);
  };

  return ( 
    <>
      <div className="profile" style={{left: toggler }}>
       
        <div className="profile_hud">

          <div className="col">
            <div
              className="live_indicator"
              style={{ backgroundColor: live ? "aqua" : "transparent" }}
            ></div>
            {live ? "Charles, is Live!" : null}
          </div>

          <Avatar isUpdateable={true} />

          <div className="info">
            <span>
              Name: <h2>{name}</h2>
              <button
                onClick={() => {
                  if (toggle) {
                    setToggle(false);
                  } else {
                    setToggle(true);
                  }
                }}
              >
                +
              </button>
            </span>

            <form onSubmit={(e) => update(e)}>
              <input
                type="text"
                placeholder="Update name"
                style={{ height: toggle ? "30px" : "0px" }}
                onChange={(e) => setValue(e.target.value)}
              />
            </form>
            <span>
              Email: <h2>{email}</h2>
            </span>
            <span>
              Status: <h2>{status}</h2>
            </span>
            <span>
              Points: <h2>{points}</h2>
            </span>
            <span>
              <h5>Date Created: </h5>
              <h6>{created}</h6>
            </span>
            <span>
              <h5>Last Log In: </h5>
              <h6>{log}</h6>
            </span>
          </div>

          <Subscription />
        </div>

        {/* <div className="live_view" style={{ display: live ? "grid" : "none" }}>
          <JoinLive joinCode={room} />
        </div> */}

      </div>
      
    </>
  );
}

export default Profile;
