import React, { useEffect, useState } from 'react'
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { db } from '../../utils/Firebase-Config';

function HeroBanner() {

    const [hero, setHero] = useState()

    useEffect(() => {
  
      const userData = async () => {
  
        const q = query(collection(db, "heroes"), orderBy("timestamp"));
  
        onSnapshot(q, (querySnapshot) => {
          querySnapshot.forEach((doc) => {
              console.log(doc.data().path );
              setHero(doc.data().path)
            });
        })
        
      };
  
      userData()
  
    }, [])
    
  return (
    <div className="hero" style={{backgroundImage: `url(${hero})`}}></div>
  )
}

export default HeroBanner